import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';
// User defined components
import { withAnalytics } from "../App";
import BlogFeed from "../modules/blog/BlogFeed";
import BlogDetail from "../modules/blog/BlogDetail";
import AuthPage from "../modules/auth/AuthPage";
// import Home from "../modules/main/Home";
import Home from "../modules/main/NewHome";
import InfluencerJobPage from "../modules/job/InfluencerDetails";
import CategoryModel from "../models/CategoryModel";
import axiosInstance from "../config/Axios";
export function PublicRoutes() {
  const StoreAndRedirect = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
      const currentPath = location.pathname + location.search; // Get the current path and query string
      localStorage.setItem('redirectAfterLogin', currentPath);
      navigate('/auth/login');
    }, [navigate, location]);

    return null;
  };
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/home" />} />
      <Route
        exact
        path="/blog"
        element={React.createElement(withAnalytics(BlogFeed))}
      />
      <Route
        exact
        path="/blog/:slug"
        element={React.createElement(withAnalytics(BlogDetail))}
      />
      <Route exact path="/job/:id" element={React.createElement(withAnalytics(InfluencerJobPage))} />
      <Route
        exact
        path="/auth/*"
        element={React.createElement(withAnalytics(AuthPage))}
      />
      <Route path="/home" element={React.createElement(withAnalytics(Home))} />
    </Routes>
  );
}
