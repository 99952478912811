/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Navbar, Image } from "react-bootstrap";

/* Importing FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowUpRightFromSquare,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";

/* Importing custom components */
import Appbar from "../../components/Appbar";
import { useIsMobile, useIsTablet } from "../../config/Screen";

const Home = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const handleClick = () => {
    navigate("/auth/login");
  };

  function LaptopHome() {
    return (
      <>
        <Container fluid className="app-home-container mb-5">
          {!isMobile && (
            <>
              <Row className="justify-content-center align-items-center text-center crimson-pro-extralight app-home-container-h1 mt-5">
                Collaborate with
              </Row>
              <Row className="justify-content-center align-items-center text-center crimson-pro-extralight app-home-container-h1">
                content creators & other professionals
              </Row>
              <Row className="justify-content-center align-items-center text-center app-home-container-h2 mt-2">
                India's first platform uniting content creators, influencers,
                and professionals to
              </Row>
              <Row className="justify-content-center align-items-center text-center app-home-container-h2 mt-2">
                collaborate and succeed
              </Row>
              <Row className="justify-content-center align-items-center mt-4">
                <Row
                  className="justify-content-center align-items-center text-center poppins-semibold app-home-container-btn"
                  onClick={handleClick}
                >
                  JOIN NOW
                </Row>
              </Row>
            </>
          )}
          {isMobile && (
            <>
              <Row className="justify-content-center crimson-pro-extralight align-items-center text-center app-home-container-h1-mobile mt-5">
                Collaborate with{" "}
              </Row>
              <Row className="justify-content-center crimson-pro-extralight align-items-center text-center app-home-container-h1-mobile">
                content creators & other{" "}
              </Row>
              <Row className="justify-content-center crimson-pro-extralight align-items-center text-center app-home-container-h1-mobile">
                professionals{" "}
              </Row>
              <Row className="justify-content-center align-items-center text-center app-home-container-h2-mobile mt-2">
                India's first platform uniting content creators,
              </Row>
              <Row className="justify-content-center align-items-center text-center app-home-container-h2-mobile">
                {" "}
                influencers, and professionals to
              </Row>
              <Row className="justify-content-center align-items-center text-center app-home-container-h2-mobile">
                {" "}
                collaborate and succeed
              </Row>
              <Row
                className="justify-content-center align-items-center mt-3"
                onClick={handleClick}
              >
                <Row className="justify-content-center align-items-center text-center poppins-semibold app-home-container-btn-mobile mt-3">
                  JOIN NOW
                </Row>
              </Row>
            </>
          )}
          {isMobile && (
            <>
              <Row className="mx-auto app-imagesrow-mob">
                <Col className="scroll-container position-relative">
                  <Col className="d-flex app-newhome-images1 justify-content-center position-relative">
                    <img
                      className="app-image-mob1"
                      src="/newhomed1.png"
                      alt="Responsive image"
                    />
                    <Col className="app-home-bookbtn app-newhome-bgc-videographer position-absolute poppins-light start-0 bottom-0 ms-4">
                      Make-up artists
                    </Col>
                    <Col className="app-home-comingbtn app-newhome-bgc-videographer poppins-light position-absolute start-0 bottom-0 ms-4">
                      and models
                    </Col>
                  </Col>
                  <Col className="d-flex app-newhome-images1 justify-content-center position-relative ms-4">
                    <img
                      className="app-image-mob2"
                      src="/newhomem2.png"
                      alt="Responsive image"
                    />
                    <Col className="app-home-bookbtn app-newhome-bgc-makeup-artist poppins-light position-absolute start-0 bottom-0 ms-4">
                      Professional
                    </Col>
                    <Col className="app-home-comingbtn app-newhome-bgc-makeup-artist poppins-light position-absolute start-0 bottom-0 ms-4">
                      Photographer
                    </Col>
                  </Col>
                  <Col className="d-flex app-newhome-images1 justify-content-center position-relative ms-4">
                    <img
                      className="app-image-mob3"
                      src="/newhomed3.png"
                      alt="Responsive image"
                    />
                    <Col className="app-newhome-ve position-absolute start-0 bottom-0">
                      <span className="app-newhome-ve-text poppins-light">
                        Video editors
                      </span>
                    </Col>
                  </Col>
                </Col>
              </Row>
            </>
          )}
          {!isMobile && (
            <>
              <Row className="mx-auto position-relative">
                <span className="app-newhome-vectortop">
                  <img
                    className="img-fluid position-absolute end-0 me-5"
                    src="/vector2.png"
                    alt="Responsive image"
                  />
                  <img
                    className="img-fluid position-absolute ms-5"
                    src="/vector.png"
                    alt="Responsive image"
                  />
                </span>

                <Col className="app-newhome-images1 d-flex justify-content-center position-relative">
                  <img
                    className="app-home-container-image1"
                    src="/newhomed1.png"
                    alt="Responsive image"
                  />
                  <Col className="app-home-bookbtn app-newhome-bgc-videographer position-absolute poppins-light start-0 bottom-0 ms-4">
                    Make-up artists
                  </Col>
                  <Col className="app-home-comingbtn app-newhome-bgc-videographer poppins-light position-absolute start-0 bottom-0 ms-4">
                    and models
                  </Col>
                </Col>

                <Col className="app-newhome-images2 d-flex justify-content-center position-relative ms-4">
                  <img
                    className="app-home-container-image1"
                    src="/newhomed2.png"
                    alt="Responsive image"
                  />
                  <Col className="app-home-bookbtn app-newhome-bgc-makeup-artist poppins-light position-absolute start-0 bottom-0 ms-4">
                    Professional
                  </Col>
                  <Col className="app-home-comingbtn app-newhome-bgc-makeup-artist poppins-light position-absolute start-0 bottom-0 ms-4">
                    Photographer
                  </Col>
                </Col>
                <Col className="app-newhome-images3 d-flex justify-content-center position-relative ms-4">
                  <img
                    className="app-home-container-image1"
                    src="/newhomed3.png"
                    alt="Responsive image"
                  />
                  <Col className="app-newhome-ve position-absolute start-0 bottom-0">
                    <span className="app-newhome-ve-text poppins-light">
                      Video editors
                    </span>
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </>
    );
  }
  return (
    <>
      {/* <Appbar />a */}
      <div className="mx-auto mt-5">
        <Navbar.Brand href="https://letzcreate.in" className="app-appbar-title">
          <Image src={require("../../assets/img/newlogo.png")} />
        </Navbar.Brand>
      </div>
      {LaptopHome()}
    </>
  );
};

export default Home;
